@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800&display=swap");

/* base styles */
body {
  font-family: Raleway, sans-serif;
  margin: 0 auto;
  font-size: 1.5em;
  text-align: center;
  height: 100vh;
  /* background: rgb(20, 4, 163); */
  background: linear-gradient(90deg, rgb(6, 1, 55), rgb(20, 4, 163), rgb(6, 1, 55));
  color: #fff;
  text-shadow: 4px 4px 3px #ff0000;
}
body h4 {
  font-family: Raleway, sans-serif;
  margin: 23px;
  font-size: 0.8em;
  text-align: center;
  color: #fff;
  text-shadow: none;
}
